import { cacheSendEventTimestamp, getCacheConfig } from "../core/Cache";
import { getAttachments } from "../utils/callbacksToPromises";
import {
  checkIsOfficeOnline,
  // closeDialog,
  dialogCreator,
  getEmailWarnings,
  // openDialog,
  // processDialogResponse,
  processMailBoxAttachmentResponse,
} from "../utils/utils";

async function processOnSendEvent(event: Office.AddinCommands.Event) {
  const mailboxItem = Office.context.mailbox.item;

  const config = await getCacheConfig();

  if (checkIsOfficeOnline()) {
    await cacheSendEventTimestamp(Date.now());
  }

  // get the email warnings for mail item
  const emailWarnings = await getEmailWarnings(mailboxItem, config.specificDomains);

  // get attachments ids and names to show in dialog
  const attachmentsFromMailBox = await getAttachments(mailboxItem);
  const stateAttachmentArray = processMailBoxAttachmentResponse(attachmentsFromMailBox, config.defaultOption);

  // if cleaning disable and error is not there don't show dialog
  if (!config.enableCleaning) {
    if (emailWarnings.length === 0) {
      return event.completed({ allowEvent: true });
    }
  }

  // if cleaning enabled but not attachments to process don't show dialog.
  // if cleaning enabled but warning is there show the warning dialog
  if (stateAttachmentArray.length === 0) {
    if (emailWarnings.length === 0) {
      return event.completed({ allowEvent: true });
    }
    await dialogCreator(event, true);
    return;
  }

  await dialogCreator(event, false);
}

export default processOnSendEvent;
