import getGlobal from "./src/getGlobal";
import processOnSendEvent from "./src/onSend/onSend";

Office.onReady(() => {
  // If needed, Office.js is ready to be called
});

let g = getGlobal() as any;

// the add-in command functions need to be available in global scope
g.processOnSendEvent = processOnSendEvent;
